import { Mockup } from '@interfaces/mockup.interface';
export const EMPLOYEES_TYPES_RESPONSE_MOCKUP: Mockup = {
    success: [
        {
            response: {
                "data": {
                    id: 1,
                    titulo: '¿Qué tipo de trabajador eres?',
                    descripcion: '<p><strong>Estos son los tipos de trabajadores y cotizantes a AFP PlanVital:</strong></p>\n<ul>\n<li><strong>Trabajador independiente</strong><br>Emiten boletas por un monto bruto anual, igual o mayor a 5 ingresos m&iacute;nimos.</li>\n<li><strong>Trabajador Casa Particular</strong><br>Personas que trabajan a jornada completa o parcial, de forma continua o discontinua, al servicio de personas naturales, que pueden ser familias u otros, en labores referentes al hogar.<br>Trabajadores de casa particular tambi&eacute;n son quienes realizan labores en instituciones de beneficencia, de protecci&oacute;n de hogar o choferes puertas adentro.</li>\n<li><strong>Trabajador T&eacute;cnico Extranjero</strong><br>Es aquel trabajador que posee estudios en su pa&iacute;s de origen. Se puede acoger a la Ley de T&eacute;cnico Extranjero 18.156, si cumple con los requisitos para ello.</li>\n<li><strong>Trabajos pesados</strong><br>Son aquellos trabajadores que sufren desgaste f&iacute;sico y mental debido a las labores que realizan.</li>\n</ul>'
                }
            }
        }
    ],
    failures: []
};
