import { Mockup } from '@interfaces/mockup.interface';

const pageKey = 'EMPLOYER.PAYMENT.SHEETS';

export const PAYMENT_FORM_RESPONSE_MOCKUP: Mockup = {
    success: [
        {
            response: {
                "data": {
                    employer: {
                        title: 'Planillas de pago para empleadores',
                        urlSectionList: [
                            {
                                title: 'Pago Cotizaciones Previsionales y Ahorro Voluntario.',
                                url: 'Planillas-Pago/Empleadores/PAGO-COTIZACIONES-PREVISIONALES-Y-AHORRO-VOLUNTARIO.pdf',
                            },
                            {
                                title: 'Declaración y No Pago Cotizaciones Previsionales.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION-Y-NO-PAGO-COTIZACIONES-PREVISIONALES.pdf',
                            },
                            {
                                title: 'Pago Cotizaciones Voluntarias Convenidos y Aportes Indemnización.',
                                url: 'Planillas-Pago/Empleadores/PAGO%20COTIZACIONES%20VOLUNTARIAS%20CONVENIDOS%20Y%20APORTES%20DE%20INDEMNIZACION.pdf',
                            },
                            {
                                title: 'Declaración y No Pago Cotizaciones Voluntarias Convenidos y Aportes Indemnización.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION%20Y%20NO%20PAGO%20COTIZACIONES%20VOLUNTARIAS%20CONVENIDOS%20Y%20APORTES.pdf',
                            },
                            {
                                title: 'Pago Cotizaciones Previsionales Subsidios Incapacidad Laboral.',
                                url: 'Planillas-Pago/Empleadores/PAGO%20COTIZACIONES%20PREVISIONALES%20SUBSIDIOS%20INCAPACIDAD%20LABORAL.pdf',
                            },
                            {
                                title: 'Declaración y No Pago Cotizaciones Previsionales Subsidios Incapacidad Laboral.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION%20Y%20NO%20PAGO%20COTIZACIONES%20PREVISIONALES%20SUBSIDIOS%20INCAPACIDAD%20LABORAL.pdf',
                            },
                            {
                                title: 'Pago Trabajador Casa Particular.',
                                url: 'Planillas-Pago/Empleadores/PAGO%20TRABAJADOR%20CASA%20PARTICULAR.pdf',
                            },
                            {
                                title: 'Pago De Declaración Para Trabajador De Casa Particular.',
                                url: 'Planillas-Pago/Empleadores/PAGO%20DECLARACION%20TRABAJADOR%20CASA%20PARTICULAR%20INDEMNIZACION%20OBLIGATORIA.pdf',
                            },
                            {
                                title: 'Declaración y No Pago Trabajador Casa Particular.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION%20Y%20NO%20PAGO%20TRABAJADOR%20CASA%20PARTICULAR.pdf',
                            },
                            {
                                title: 'Pago Cotizaciones Trabajos Pesados.',
                                url: 'Planillas-Pago/Empleadores/PAGO%20COTIZACIONES%20TRABAJOS%20PESADOS.pdf',
                            },
                            {
                                title: 'Declaración y No Pago Cotizaciones Por Trabajos Pesados.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION%20Y%20NO%20PAGO%20COTIZACIONES%20POR%20TRABAJOS%20PESADOS.pdf',
                            },
                            {
                                title: 'Declaraciones y No Pago cotizaciones Afiliados Voluntario.',
                                url: 'Planillas-Pago/Empleadores/DECLARACION%20Y%20NO%20PAGO%20COTIZACIONES%20AFILIADO%20VOLUNTARIO.pdf',
                            },
                        ],
                    },
                    person: {
                        title: 'Planillas de pago para personas',
                        urlSectionList: [
                            {
                                title: 'Pago Cotizaciones Previsionales Independiente.',
                                url: 'Planillas-Pago/Clientes/planilla-pago-cotizaciones-previsionales.pdf',
                            },
                            {
                                title: 'Pago Del Saldo Neto Por Cotizar Para Independientes.',
                                url: 'Planillas-Pago/Clientes/PAGO%20DEL%20SALDO%20NETO%20POR%20COTIZAR%20PARA%20INDEPENDIENTES.pdf',
                            },
                            {
                                title: 'Depósito Directo Cuenta Cotización Voluntaria.',
                                url: 'Planillas-Pago/Clientes/DEPOSITO%20DIRECTO%20CUENTA%20COTIZACION%20VOLUNTARIA.pdf',
                            },
                            {
                                title: 'Planilla de Pago de Cotizaciones de Afiliado Voluntario.',
                                url: 'Planillas-Pago/Clientes/PLANILLA%20DE%20PAGO%20COTIZACIONES%20DE%20AFILIADO%20VOLUNTARIO.pdf',
                            },
                        ],
                    },
                }
            }
        }
    ],
    failures: []
};
