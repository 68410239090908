import { Password, PasswordMapped } from '@interfaces/employer.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const PASSWORD_AND_CERTIFICATES_RESPONSE: Mockup = {
    success: [{
        response: {
            data: {
                titulo_clave: 'Obtén tu clave como empleador y accede a tus servicios en línea.',
                descripcion_clave: '<h3>&iquest;Qu&eacute; puedes hacer?</h3>\n<ul>\n<li>Actualizar datos de tu empresa</li>\n<li>Comprobar vigencia de afiliaci&oacute;n de tus trabajadores</li>\n<li>Obtener certificados de cotizaciones</li>\n<li>Consultar deuda previsional</li>\n<li>Consultar Bolet&iacute;n Comercial</li>\n<li>C&aacute;lculo de intereses y reajustes</li>\n</ul>',
                titulo_certificados: 'Estás a un solo clic de obtener tus certificados de forma rápida y segura.',
                descripcion_certificados: '<ul>\n<li>Certificados de Cotizaciones trabajador</li>\n<li>Certificado de deuda actualizada</li>\n<li>Certificado de Afiliaci&oacute;n Trabajador</li>\n</ul>\n<p>Para obtener Certificados directamente de nuestro sitio Web, debes contar con el Rut de la empresa y la Clave de Acceso.</p>\n<p>Si necesitas validar tus Certificados,&nbsp;<a href=\'http://localhost:4200/afiliado/asesoria/certificados/validar\'>ingresa aqu&iacute;</a></p>\n<p>Si necesitas otros Certificados, cont&aacute;ctate con nuestro Contact Center al&nbsp;<a href=\'tel:800072072\'>800 072 072</a>.</p>',
                titulo_disclaimer: '¿Cómo solicito la Clave?',
                descripcion_disclaimer: '<p><strong>Ingresa a la opci&oacute;n solicita tu clave en nuestra oficina virtual empleador y completa los datos requeridos en el formulario y env&iacute;alo. En un plazo m&aacute;ximo de 2 d&iacute;as h&aacute;biles, una ejecutiva de AFP PlanVital te contactar&aacute; y te solicitar&aacute;:</strong></p>\n<ul>\n<li>Copia del ROL tributario de la empresa</li>\n<li>Copia de la C&eacute;dula de Identidad por ambos lados del representante legal de tu empresa.</li>\n<li>Copia de la C&eacute;dula de Identidad del solicitante por ambos lados.</li>\n</ul>',
                titulo_preguntas: 'Preguntas frecuentes de tu clave y certificados',
                descripcion_preguntas: 'Revisa las preguntas frecuentes y aclara tus dudas sobre la clave y certificados de AFP PlanVital',
                preguntas: [
                    {
                        faq_id: {
                            pregunta: '¿Qué requisitos necesito para obtener mi clave de empleador?',
                            respuesta: '<p>Debes ser empleador o representante legal de la empresa. Estos son los documentos requeridos:</p>\n<p>Si lo haces en el sitio web:</p>\n<ul>\n<li>Rut empresa (documento emitido por SII)</li>\n<li>C&eacute;dula de Identidad vigente del representante legal de la empresa</li>\n</ul>\n<p>Si lo haces en sucursales:</p>\n<ul>\n<li>Rut empresa (documento emitido por el SII)</li>\n<li>Copia de c&eacute;dula de identidad vigente del representante legal de la empresa. Si quien realiza el tr&aacute;mite no es el representante legal de la empresa debe presentar una carta que contenga los datos de la empresa con: nombre, RUT, direcci&oacute;n y tel&eacute;fono, firmada por el empleador</li>\n</ul>'
                        }
                    },
                    {
                        faq_id: {
                            pregunta: '¿Cómo puedo validar un certificado?',
                            respuesta: '<p>A trav&eacute;s de nuestra secci&oacute;n de <a title=\'Validaci&oacute;n de Certificados Online\' href=\'afiliado/asesoria/certificados/validar\' target=\'_blank\' rel=\'noopener\'>Validaci&oacute;n Online de Certificados</a> puedes verificar la validez de los Certificados. Para ello, se debe ingresar el RUT del Afiliado o Empleador y el folio del certificado.</p>'
                        }
                    }
                ]
            }
        }
    }],
    failures: []
};

export const PASSWORD_AND_CERTIFICATES_MOCK: Password = {
    banner: {
        id: 1,
        image: {
            alt: 'Cómo Solicitar Clave AFP',
            url: 'assets/images/01.banner-claves.webp',
        },
        image_mobile: {
            alt: 'Cómo Solicitar Clave AFP',
            url: 'assets/images/01.banner-claves.webp',
        },
        firstLine: `Como obtener tu clave de AFP`,
    },

    faqSection: {
        title: 'Preguntas frecuentes de tu clave y certificados',
        subTitle: 'Revisa las preguntas frecuentes y aclara tus dudas sobre la clave y certificados de AFP PlanVital',
        faq: [
            {
                title: '¿Qué requisitos necesito para obtener mi clave de empleador?',
                description: '<p>Debes ser empleador o representante legal de la empresa. Estos son los documentos requeridos:</p>\n<p>Si lo haces en el sitio web:</p>\n<ul>\n<li>Rut empresa (documento emitido por SII)</li>\n<li>C&eacute;dula de Identidad vigente del representante legal de la empresa</li>\n</ul>\n<p>Si lo haces en sucursales:</p>\n<ul>\n<li>Rut empresa (documento emitido por el SII)</li>\n<li>Copia de c&eacute;dula de identidad vigente del representante legal de la empresa. Si quien realiza el tr&aacute;mite no es el representante legal de la empresa debe presentar una carta que contenga los datos de la empresa con: nombre, RUT, direcci&oacute;n y tel&eacute;fono, firmada por el empleador</li>\n</ul>'
            },
            {
                title: '¿Cómo puedo validar un certificado?',
                description: '<p>A trav&eacute;s de nuestra secci&oacute;n de <a title=\'Validaci&oacute;n de Certificados Online\' href=\'afiliado/asesoria/certificados/validar\' target=\'_blank\' rel=\'noopener\'>Validaci&oacute;n Online de Certificados</a> puedes verificar la validez de los Certificados. Para ello, se debe ingresar el RUT del Afiliado o Empleador y el folio del certificado.</p>'
            }
        ]
    },
    getPasswordSection: {
        title: 'Obtén tu clave como empleador y accede a tus servicios en línea.',
        description: '<h3>&iquest;Qu&eacute; puedes hacer?</h3>\n<ul>\n<li>Actualizar datos de tu empresa</li>\n<li>Comprobar vigencia de afiliaci&oacute;n de tus trabajadores</li>\n<li>Obtener certificados de cotizaciones</li>\n<li>Consultar deuda previsional</li>\n<li>Consultar Bolet&iacute;n Comercial</li>\n<li>C&aacute;lculo de intereses y reajustes</li>\n</ul>'
    },
    getCertificateSection: {
        title: 'Estás a un solo clic de obtener tus certificados de forma rápida y segura.',
        description: '<ul>\n<li>Certificados de Cotizaciones trabajador</li>\n<li>Certificado de deuda actualizada</li>\n<li>Certificado de Afiliaci&oacute;n Trabajador</li>\n</ul>\n<p>Para obtener Certificados directamente de nuestro sitio Web, debes contar con el Rut de la empresa y la Clave de Acceso.</p>\n<p>Si necesitas validar tus Certificados,&nbsp;<a href=\"http://localhost:4200/afiliado/asesoria/certificados/validar\">ingresa aqu&iacute;</a></p>\n<p>Si necesitas otros Certificados, cont&aacute;ctate con nuestro Contact Center al&nbsp;<a href=\"tel:800072072\">800 072 072</a>.</p>'
    },
    requestPasswordSection: {
        title: '¿Cómo solicito la Clave?',
        description: '<p><strong>Ingresa a la opci&oacute;n solicita tu clave en nuestra oficina virtual empleador y completa los datos requeridos en el formulario y env&iacute;alo. En un plazo m&aacute;ximo de 2 d&iacute;as h&aacute;biles, una ejecutiva de AFP PlanVital te contactar&aacute; y te solicitar&aacute;:</strong></p>\n<ul>\n<li>Copia del ROL tributario de la empresa</li>\n<li>Copia de la C&eacute;dula de Identidad por ambos lados del representante legal de tu empresa.</li>\n<li>Copia de la C&eacute;dula de Identidad del solicitante por ambos lados.</li>\n</ul>'
    }
};
