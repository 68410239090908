import { ExcessPayment } from '@interfaces/employer.interface';
import { Mockup } from '@interfaces/mockup.interface';


export const GET_EXCESS_PAYMENT_RESPONSE_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Solicitud de devolución de Pago en Exceso',
                contenido: '<p>Los excesos son cotizaciones pagadas a los trabajadores que sobrepasan los límites legales.</p><p><strong>&iquest;En qu&eacute; situaciones se puede generar un pago en exceso?</strong></p>\n<ul>\n<li>Cuando hay diferencias a favor entre el monto pagado efectivamente en comparaci&oacute;n a lo informado en lasrespectivas planillas de pago</li>\n<li>Por pagos duplicados.</li>\n<li>Por pagos indebidos (trabajador finiquitado, rentas err&oacute;neas, licencias m&eacute;dicas, entre otros).</li>\n</ul>\n<p><strong>Para solicitar tu devoluci&oacute;n, descarga, completa y env&iacute;a el formulario a empleadores@planvital.cl con los siguientes documentos:</strong></p>\n<ul>\n<li>Excesos por licencias m&eacute;dicas<br><br>\n<ul>\n<li>Liquidaciones de sueldo</li>\n<li>N&oacute;mina detalle trabajdores</li>\n</ul>\n</li>\n<li>Pagos indebidos<br><br>\n<ul>\n<li>Planillas de pago</li>\n<li>Liquidaciones de sueldo,</li>\n<li>Respaldos de AFC de a renta del mes solicitado por cada trabajador involucrado si corresponde</li>\n<li>Finiquito trabajador si corresponde</li>\n<li>Resoluci&oacute;n de entidad p&uacute;blica si corresponde</li>\n</ul>\n</li>\n</ul>',
                texto_boton_descarga: 'Descargar el formulario',
                archivo: {
                    filename_disk: '77a2e857-07a9-4bc9-aad6-2bda300ddcdb.pdf'
                }
            }
        }
    }],
    failures: []
};


export const ExcessPaymentContent: ExcessPayment = {
    banner: {
        image: {
            url: 'test.jpg',
            alt: 'Test'
        },
        firstLine: 'Test'
    },
    buttonLabel: 'Test',
    pdfFile: {
        downloadButtonText: 'Test',
        downloadFileName: 'Test',
        downloadUrl: 'Test'
    },
    title: 'Test',
    description: 'Test'
};
