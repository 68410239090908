import { Component, OnInit } from '@angular/core';
import { HOME_URL } from '@constants/header.constant';
import { FooterColumn } from '@interfaces/footer.interface';
import * as CONTENT from '@pages-content/footer.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { RouterService } from '@providers/router/router.service';
import { CmsService } from '@services/cms/cms.service';
import { CmsUtils } from '@utils/cms.utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public columns: Array<FooterColumn>;
  public content = CONTENT;
  public loading = false;
  public lang: string;

  public get isAffiliateHome(): boolean {
    return this.routerService.currentUrl === HOME_URL.affiliate;
  }

  constructor(
    public font: FontService,
    private navService: NavigationService,
    private cmsService: CmsService,
    private routerService: RouterService,
    private languageProvider: LanguageProvider,
    private cmsUtils: CmsUtils,
  ) { }

  public ngOnInit(): void {
    this.getFooter();
    this.languageSubscription();
  }

  private getFooter(): void {
    this.lang = this.cmsUtils.getLanguage();
    this.loading = true;
    this.cmsService.loadFooterContent().toPromise()
      .then((response) => {
        this.columns = response.columns,
          this.content = {
            ...this.content,
            CERTIFICATE_DISCLAIMER: response.section_3.disclaimer,
            CERTIFICATES_FIRST: response.section_3.certificates_first,
            CERTIFICATES_SECOND: response.section_3.certificates_second
          };
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => {
      this.getFooter();
    });
  }

  public goToUrl(event: any, url: string): void {
    event.preventDefault();
    if (!url) { return; }
    this.navService.goTo(url);
  }
}
