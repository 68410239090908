import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { GO_BACK } from '@constants/pages-content/general.constant';
import { Card } from '@interfaces/info-card.interface';
import { MandateModalContent } from '@interfaces/mandate-modal.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-modal-mandate',
  templateUrl: './modal-mandate.component.html',
  styleUrls: ['./modal-mandate.component.scss'],
})
export class ModalMandateComponent extends ModalComponent {
  public information: MandateModalContent;
  public backButton = GO_BACK;
  modalInformation: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private utils: Utils,
    private navService: NavigationService

  ) {
    super(params, modalCtrl);
    this.information = this.params.get('information');
  }

  public downloadMandate(downloadURL: string, fileName: string): void {
    this.utils.downloadFile(downloadURL, fileName);
  }

  goToMandate(): void {
    this.navService.goTo(this.information.requestUrl);
    this.closeModal();
  }
}
