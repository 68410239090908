<div class="modal modal-information-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="information-content modal-frame">
    <div class="title" [ngClass]="font?.size" [innerHTML]="information?.title"></div>
    <div class="description dynamic-text tertiary" [ngClass]="font?.size"></div>
    <b class="centered-container sub-title">{{information?.subTitle}}</b>
    <br>
    <div class="gray-alert">
      <div class="icon-alert">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <p>{{information?.disclaimer}}</p>
      </div>
    </div>
    <div>
      <p class="sub-title">
        {{information?.step1Title}}
      </p>
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate(information?.notarialMandateFile, information?.notarialMandateName)">
        <ion-button class="button secondary">
          {{information?.notarialMandateName}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate(information?.hospitalizedMandateFile, information?.hospitalizedMandateName)">
        <ion-button class="button secondary">
          {{information?.hospitalizedMandateName}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate(information?.prisonerMandateFile, information?.prisonerMandateName)">
        <ion-button class="button secondary">
          {{information?.prisonerMandateName}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <p class="sub-title mt-25">{{information?.step2Title}}</p>
    </div>
    <div class="pt-5">
      <p class="align" [innerHTML]="information?.step2Description"></p>
    </div>

    <div class="pt-5">
    </div>
    <div class="centered-container">
      <ion-button class="button primary back" [ngClass]="font?.size" (click)="goToMandate()">{{information?.textModalButton}}</ion-button>
    </div>
    <div class="centered-container pt-5 mobile" (click)="closeModal()">
      <p class="sub-title mt-25">Volver</p>
    </div>
  </div>