import { PrimaryOption } from '@interfaces/header.interface';

const sectionKey = 'AFFILIATE.ASSESSMENT.FINANCIAL_INFORMATION';
const certificateSectionKey = 'AFFILIATE.ASSESSMENT.CERTIFICATES';
const tabs = 'TABS';

export const MAIN_FINANCIAL_INFORMATION: { [key: string]: PrimaryOption } = {
  quotaValue: {
    name: `${sectionKey}.${tabs}.QUOTA_VALUE`,
    url: 'afiliado/asesoria/informacion-financiera/valor-cuota'
  },
  economicIndicators: {
    name: `${sectionKey}.${tabs}.ECONOMIC_INDICATORS`,
    url: 'afiliado/asesoria/informacion-financiera/indicadores-economicos',
  },
  investmentPortfolio: {
    name: `${sectionKey}.${tabs}.INVESTMENT_PORTFOLIO`,
    url: 'afiliado/asesoria/informacion-financiera/cartera-inversiones',
  },
  profitabilityVariation: {
    name: `${sectionKey}.${tabs}.PROFITABILITY_VARIATION`,
    url: 'afiliado/asesoria/informacion-financiera/variacion-rentabilidad',
  },
};

export const MAIN_CERTIFICATES: { [key: string]: PrimaryOption } = {
  getCertificates: {
    name: `${certificateSectionKey}.${tabs}.GET_CERTIFICATES`,
    url: 'afiliado/asesoria/certificados/obtener'
  },
  validateCertificates: {
    name: `${certificateSectionKey}.${tabs}.VALIDATE_CERTIFICATES`,
    url: 'afiliado/asesoria/certificados/validar',
  }
};

export const PROCEDURES_AFFILIATE: { [key: string]: PrimaryOption } = {
  newProcedure: {
    name: `${tabs}.NEW_PROCEDURE`,
    url: 'afiliado/asesoria/centro-tramites'
  },
  getProcedures: {
    name: `${tabs}.GET_PROCEDURES`,
    url: 'afiliado/asesoria/consulta-solicitudes',
  },
  detailProcedures: {
    name: `${tabs}.GET_PROCEDURES`,
    url: 'afiliado/asesoria/detalle-solicitud',
  },
};

export const PROCEDURES_PENSIONER: { [key: string]: PrimaryOption } = {
  newProcedure: {
    name: `${tabs}.NEW_PROCEDURE`,
    url: 'pensionado/asesoria/centro-tramites'
  },
  getProcedures: {
    name: `${tabs}.GET_PROCEDURES`,
    url: 'pensionado/asesoria/consulta-solicitudes',
  }
};

export const FINANCIAL_INFORMATION_TABS: Array<PrimaryOption> = [
  {
    name: MAIN_FINANCIAL_INFORMATION.quotaValue.name,
    url: MAIN_FINANCIAL_INFORMATION.quotaValue.url,
  },
  {
    name: MAIN_FINANCIAL_INFORMATION.economicIndicators.name,
    url: MAIN_FINANCIAL_INFORMATION.economicIndicators.url,
  },
  {
    name: MAIN_FINANCIAL_INFORMATION.investmentPortfolio.name,
    url: MAIN_FINANCIAL_INFORMATION.investmentPortfolio.url,
  },
  {
    name: MAIN_FINANCIAL_INFORMATION.profitabilityVariation.name,
    url: MAIN_FINANCIAL_INFORMATION.profitabilityVariation.url,
  },
];

export const CERTIFICATES_TABS: Array<PrimaryOption> = [
  {
    name: MAIN_CERTIFICATES.getCertificates.name,
    url: MAIN_CERTIFICATES.getCertificates.url,
  },
  {
    name: MAIN_CERTIFICATES.validateCertificates.name,
    url: MAIN_CERTIFICATES.validateCertificates.url,
  }
];

export const NEW_PROCEDURE_OR_FIND_PROCEDURE: { [key: string]: PrimaryOption[] } = {
  affiliate: [
    {
      name: PROCEDURES_AFFILIATE.newProcedure.name,
      url: PROCEDURES_AFFILIATE.newProcedure.url,
    },
    {
      name: PROCEDURES_AFFILIATE.getProcedures.name,
      url: PROCEDURES_AFFILIATE.getProcedures.url,
    }
  ],
  pensioner: [
    {
      name: PROCEDURES_PENSIONER.newProcedure.name,
      url: PROCEDURES_PENSIONER.newProcedure.url,
    },
    {
      name: PROCEDURES_PENSIONER.getProcedures.name,
      url: PROCEDURES_PENSIONER.getProcedures.url,
    }
  ]
};

export const QUOTA_VALUE_PATH_URL = '/afiliado/asesoria/informacion-financiera/valor-cuota';
export const ECONOMIC_INDICATORS_URL = '/afiliado/asesoria/informacion-financiera/indicadores-economicos';
export const INVESTMENT_PORTFOLIO_URL = '/afiliado/asesoria/informacion-financiera/cartera-inversiones';
export const PROFITABILITY_VARIATION_URL = '/afiliado/asesoria/informacion-financiera/variacion-rentabilidad';
