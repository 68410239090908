import { Mockup } from '@interfaces/mockup.interface';


export const GET_CERTIFICATES_RESPONSE: Mockup = {
    success: [
        {
            response: {
                data: {
                    titulo: 'Certificados AFP',
                    subtitulo: 'Obten tus certificados en línea solo con tu RUT',
                    descripcion: 'Aquí podrás obtener los certificados en línea en los que solo necesitas validar tu RUT. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresa a nuestra Sucursal en Línea.',
                    titulo_generales: 'Certifcados generales',
                    descripcion_generales: 'Los certificados que necesitas para tu trámite',
                    certificados_generales: [
                        {
                            tipo: 'affiliate',
                            campos: [
                                'rut'
                            ],
                            titulo: 'Certificado de afiliación',
                            descripcion: 'Acredita la fecha en la que ingresaste al sistema previsional y desde cuando te incorporaste a AFP PlanVital.'
                        },
                        {
                            tipo: 'contingency_records',
                            campos: [
                                'rut'
                            ],
                            titulo: 'Certificado de antecedentes previsionales (Saldos)',
                            descripcion: 'Contiene la información de todas tus cuentas vigentes y saldos en nuestra administradora.'
                        },
                        {
                            tipo: 'residence_records',
                            campos: [
                                'rut'
                            ],
                            titulo: 'Certificado de Antecedentes de Residencia',
                            descripcion: 'Acredita la residencia oficial del cliente de AFP PlanVital.'
                        },
                        {
                            tipo: 'vacations',
                            campos: [
                                'rut'
                            ],
                            titulo: 'Certificado de Vacaciones Progresivas',
                            descripcion: 'Muestra tu identificación, fecha de ingreso al nuevo sistema previsional, tiempo cotizado en meses y años, tiempo cotizado en el antiguo sistema en meses y años.'
                        }
                    ],
                    titulo_transaccionales: 'Certificados transaccionales',
                    descripcion_transaccionales: 'Obtén claridad sobre tus saldos y movimientos.',
                    certificados_transaccionales: [
                        {
                            tipo: 'quotations',
                            campos: [
                                'rut',
                                'periodCode',
                                'producType'
                            ],
                            titulo: 'Certificado de Cotizaciones',
                            descripcion: 'Muestra las cotizaciones imponibles de los últimos 12 meses informadas por tu empleador.'
                        },
                        {
                            tipo: 'remunerations',
                            campos: [
                                'rut',
                                'periodCode'
                            ],
                            titulo: 'Certificado de Remuneraciones',
                            descripcion: 'Muestra las remuneraciones imponibles de los últimos 12 meses informadas por tu empleador'
                        }
                    ],
                    descripcion_preguntas: 'Resuelve tus dudas sobre la obtención de certificados de AFP PlanVital',
                    icono_generales: 'i-general-certificate',
                    icono_transaccionales: 'i-transactional-certificate',            
                    preguntas: [
                        {
                            faq_id: {
                                pregunta: '¿Qué necesito para obtener un certificado de la AFP?',
                                respuesta: '<p>Solo debes ingresar tu RUT y enviaremos el certificado al correo registrado en tus datos personales. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresar a nuestra <a title=\'Sucursal En L&iacute;nea\' href=\'https://sucursalenlinea.planvital.cl/ingreso\' target=\'_blank\' rel=\'noopener\'>Sucursal en L&iacute;nea</a>.</p>'
                            }
                        },
                        {
                            faq_id: {
                                pregunta: '¿Cuánto dura un certificado de AFP?',
                                respuesta: '<p>Un certificado de AFP tiene una vigencia de 35 d&iacute;as contados desde la fecha de emisi&oacute;n.</p>\n<p>Fuente: <a title=\'CMF Chile\' href=\'https://www.cmfchile.cl\' target=\'_blank\' rel=\'noopener\'>www.cmfchile.cl</a></p>'
                            }
                        }
                    ]
                }
            }
        }
    ],
    failures: []
};

export const GET_CERTIFICATES_MOCK = {
    banner: {
       id: 7,
       image: {
          url: 'https://cms-qa.planvital.cl/assets/e3fdc4ee-fd54-424c-8acd-d21475befced.webp',
          alt: 'Validación de certificados',
          title: 'Validación de certificados'
       },
       image_mobile: {
          url: 'https://cms-qa.planvital.cl/assets/e3fdc4ee-fd54-424c-8acd-d21475befced.webp',
          alt: 'Validación de certificados',
          title: 'Validación de certificados'
       },
       icon: 'accept',
       firstLine: '<strong>Validaci&oacute;n de certificados</strong>'
    },
    getCertificates: {
       mainTitle: 'Certificados AFPP',
       title: 'Obten tus certificados en línea solo con tu RUTT',
       description: 'Aquí podrás obtener los certificados en línea en los que solo necesitas validar tu RUT. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresa a nuestra Sucursal en Línea.....',
       certificates: [
          {
             name: 'Certifcados generales',
             description: 'Los certificados que necesitas para tu trámite',
             icon: 'i-general-certificate',
             certificatesList: [
                {
                   name: 'Certificado de afiliación',
                   type: 'affiliate',
                   description: 'Acredita la fecha en la que ingresaste al sistema previsional y desde cuando te incorporaste a AFP PlanVital.',
                   fields: [
                      'rut'
                   ]
                },
                {
                   name: 'Certificado de antecedentes previsionales (Saldos)',
                   type: 'contingency_records',
                   description: 'Contiene la información de todas tus cuentas vigentes y saldos en nuestra administradora.',
                   fields: [
                      'rut'
                   ]
                },
                {
                   name: 'Certificado de Antecedentes de Residencia',
                   type: 'residence_records',
                   description: 'Acredita la residencia oficial del cliente de AFP PlanVital.',
                   fields: [
                      'rut'
                   ]
                },
                {
                   name: 'Certificado de Vacaciones Progresivas',
                   type: 'vacations',
                   description: 'Muestra tu identificación, fecha de ingreso al nuevo sistema previsional, tiempo cotizado en meses y años, tiempo cotizado en el antiguo sistema en meses y años.',
                   fields: [
                      'rut'
                   ]
                }
             ]
          },
          {
             name: 'Certificados transaccionales',
             description: 'Obtén claridad sobre tus saldos y movimientos.',
             icon: 'i-transactional-certificate',
             certificatesList: [
                {
                   name: 'Certificado de Cotizaciones',
                   type: 'quotations',
                   description: 'Muestra las cotizaciones imponibles de los últimos 12 meses informadas por tu empleador.',
                   fields: [
                      'rut',
                      'periodCode',
                      'producType'
                   ]
                },
                {
                   name: 'Certificado de Remuneraciones',
                   type: 'remunerations',
                   description: 'Muestra las remuneraciones imponibles de los últimos 12 meses informadas por tu empleador',
                   fields: [
                      'rut',
                      'periodCode'
                   ]
                }
             ]
          }
       ]
    },
    faqSection: {
       title: 'Preguntas frecuentes sobre la obtención de certificados',
       subTitle: 'Resuelve tus dudas sobre la obtención de certificados de AFP PlanVital',
       faq: [
          {
             title: '¿Qué necesito para obtener un certificado de la AFP?',
             description: '<p>Solo debes ingresar tu RUT y enviaremos el certificado al correo registrado en tus datos personales. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresar a nuestra <a title=\'Sucursal En L&iacute;nea\' href=\'https://sucursalenlinea.planvital.cl/ingreso\' target=\'_blank\' rel=\'noopener\'>Sucursal en L&iacute;nea</a>.</p>'
          },
          {
             title: '¿Cuánto dura un certificado de AFP?',
             description: '<p>Un certificado de AFP tiene una vigencia de 35 d&iacute;as contados desde la fecha de emisi&oacute;n.</p>\n<p>Fuente: <a title=\'CMF Chile\' href=\'https://www.cmfchile.cl\' target=\'_blank\' rel=\'noopener\'>www.cmfchile.cl</a></p>'
          }
       ]
    }
 };
