<ion-content class="content">
  <app-banner [banner]="statementContent.header"></app-banner>
  <app-breadcrumb class="breadcrumb" [pathNames]="breadcrumb"></app-breadcrumb>
  <div class="information-container general-container">
    <h2 class="title" [ngClass]="font?.size">{{ statementContent.title | translate }}</h2>
    <app-accordion-download-list [list]="statementContent.downloadContent"
      [loading]="loading"></app-accordion-download-list>
    <ion-skeleton-text *ngIf="loading" animated class="skeleton-line large">
    </ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" animated class="skeleton-line large">
    </ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" animated class="skeleton-line large">
    </ion-skeleton-text>
  </div>
  <app-footer></app-footer>
</ion-content>