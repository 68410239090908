import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Banner, BannerStyle, BannerType } from '@interfaces/banner.interface';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() public banner: Banner;
  @Input() public style: BannerStyle = 'primary';
  @Input() public type: BannerType = 'header';
  @Input() public loading = false;
  @Input() public showMoreInfo = false;
  @Output() public doAction: EventEmitter<void> = new EventEmitter();

  sendAction(): void {
    this.doAction.emit();
  }
}
