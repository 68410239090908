import { Imagen } from './administration.interface';
import { Banner } from './banner.interface';

export interface ProfitabilityPercentagesResponse {
    data: ProfitabilityPercentagesData;
}

export interface ProfitabilityPercentagesData {
    id:                 number;
    banner:             Imagen;
    banner_mobile:      Imagen;
    titulo_banner:      string;
    alt_image:          string;
    status:             string;
    fondo_a_1_month:    string;
    fondo_a_1_year:     string;
    fondo_a_3_year:     string;
    fondo_a_5_year:     string;
    fondo_b_1_month:    string;
    fondo_b_1_year:     string;
    fondo_b_3_year:     string;
    fondo_b_5_year:     string;
    fondo_c_1_month:    string;
    fondo_c_1_year:     string;
    fondo_c_3_year:     string;
    fondo_c_5_year:     string;
    fondo_d_1_month:    string;
    fondo_d_1_year:     string;
    fondo_d_3_year:     string;
    fondo_d_5_year:     string;
    fondo_e_1_month:    string;
    fondo_e_1_year:     string;
    fondo_e_3_year:     string;
    fondo_e_5_year:     string;
    fechas_mes:         string;
    fechas_year:        string;
    fechas_three_years: string;
    fechas_five_years:  string;
    disclaimer:         string;
}

export interface ProfitabilityPercentagesContent {
    header:             Banner;
    titulo_banner:      string;
    alt_image:          string;
    status:             string;
    fondo_a_1_month:    string;
    fondo_a_1_year:     string;
    fondo_a_3_year:     string;
    fondo_a_5_year:     string;
    fondo_b_1_month:    string;
    fondo_b_1_year:     string;
    fondo_b_3_year:     string;
    fondo_b_5_year:     string;
    fondo_c_1_month:    string;
    fondo_c_1_year:     string;
    fondo_c_3_year:     string;
    fondo_c_5_year:     string;
    fondo_d_1_month:    string;
    fondo_d_1_year:     string;
    fondo_d_3_year:     string;
    fondo_d_5_year:     string;
    fondo_e_1_month:    string;
    fondo_e_1_year:     string;
    fondo_e_3_year:     string;
    fondo_e_5_year:     string;
    fechas_mes:         string;
    fechas_year:        string;
    fechas_three_years: string;
    fechas_five_years:  string;
    disclaimer:         string;
}
