import { MandateModalContent } from '@interfaces/mandate-modal.interface';
import { Mockup } from '@interfaces/mockup.interface';



export const MANDATE_MODAL_RESPONSE_MOCKUP: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Mandato de pago de pensión a terceros',
                subtitulo: 'Información importante',
                titulo_paso_1: 'Paso 1.- Descarga el mandato',
                nombre_archivo_1: 'Mandato notarial',
                nombre_archivo_2: 'Mandato afiliado hospitalizado',
                nombre_archivo_3: 'Mandato afiliado privado de libertad',
                disclaimer: 'Antes de iniciar el trámite, el mandatario (quien cobrará la pensión debe adjuntar los siguientes documentos según sea el caso)',
                titulo_paso_2: 'Paso 2.- Ingresa la solicitud',
                tramite_url: 'afiliado/asesoria/mandatos',
                texto_boton_modal: 'Ir al formulario',
                archivo_1: {
                    filename_disk: '1dc76303-3616-42a1-a2cb-8cbc2d1396bc.pdf'
                },
                archivo_2: {
                    filename_disk: 'ecc0021d-1288-4784-91c0-d046fa13a1dd.pdf'
                },
                archivo_3: {
                    filename_disk: '056f3aa7-4b8d-4806-b62a-a995fb580e76.pdf'
                }
            }
        }
    }],
    failures: []
};

export const MandateModalContentMock: MandateModalContent = {
    title: 'Mandato de pago de pensión a terceros',
    subTitle: 'Información importante',
    disclaimer: 'Antes de iniciar el trámite, el mandatario (quien cobrará la pensión debe adjuntar los siguientes documentos según sea el caso)',
    step1Title: 'Paso 1.- Descarga el mandato',
    notarialMandateName: 'Mandato notarial',
    notarialMandateFile: 'https://cms-qa.planvital.cl/assets/1dc76303-3616-42a1-a2cb-8cbc2d1396bc.pdf',
    hospitalizedMandateName: 'Mandato afiliado hospitalizado',
    hospitalizedMandateFile: 'https://cms-qa.planvital.cl/assets/ecc0021d-1288-4784-91c0-d046fa13a1dd.pdf',
    prisonerMandateName: 'Mandato afiliado privado de libertad',
    prisonerMandateFile: 'https://cms-qa.planvital.cl/assets/056f3aa7-4b8d-4806-b62a-a995fb580e76.pdf',
    step2Title: 'Paso 2.- Ingresa la solicitud',
    step2Description: '<p>Una vez complete el mandato junto con el documento correspondiente seg&uacute;n la solicitud, puede ingresar su solicitud desde el bot&oacute;n de&nbsp;<strong>\'Ir al formulario\'</strong></p>',
    requestUrl: 'afiliado/asesoria/mandatos',
    textModalButton: 'Ir al formulario'
};
