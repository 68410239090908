import { FooterColumn } from '@interfaces/footer.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const GET_FOOTER_MOCKUP_RESPONSE: Mockup = {
  success: [{
    response: {
      data: {
        links_columna_1: [
          { links_id: 2 },
          { links_id: 8 },
          { links_id: 34 },
          { links_id: 9 }
        ],
        links_columna_2: [
          { links_id: 4 },
          { links_id: 3 },
          { links_id: 5 },
          { links_id: 36 },
          { links_id: 7 },
          { links_id: 6 }
        ],
        links_columna_3: [
          { links_id: 15 },
          { links_id: 11 },
          { links_id: 12 },
          { links_id: 13 },
          { links_id: 1 },
          { links_id: 16 },
          { links_id: 37 },
          { links_id: 38 }
        ],
        links_columna_4: [
          { links_id: 18 },
          { links_id: 19 },
          { links_id: 20 },
          { links_id: 21 },
          { links_id: 22 },
          { links_id: 23 },
          { links_id: 24 },
          { links_id: 35 }
        ],
        titulos: [
          {
            titulo_1: "Quiénes Somos",
            titulo_2: "Inversionistas",
            titulo_3: "Información útil",
            titulo_4: "Sitios de interés",
            languages_code: "es"
          },
          {
            titulo_1: "Kiyès nou ye",
            titulo_2: "Politik AFP a",
            titulo_3: "Enfòmasyon ki itil",
            titulo_4: "Sit enterè yo",
            languages_code: "ht"
          }
        ],
        seccion_3: [
          {
            footer_seccion_3_id: {
              disclaimer: "Porque queremos ser tus Socios de por Vida, nuestro plan es entregarte la mejor experiencia, por eso nos certificamos en:",
              certificaciones: [
                {
                  certificaciones_fila_id: {
                    certificacion_imagen: [
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "3a1807e2-2ccc-4c08-b504-8e4a8ab8ffe0.png",
                            title: "Ple"
                          }
                        }
                      },
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "1be3c8d7-2a26-4c8f-a865-07b6db5fa32d.png",
                            title: "Fundacion Visibilia"
                          }
                        }
                      },
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "02c352d6-591f-4696-ac75-10a1632acf0a.png",
                            title: "Bh Compliance"
                          }
                        }
                      }
                    ]
                  }
                },
                {
                  certificaciones_fila_id: {
                    certificacion_imagen: [
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "77e6f30f-1669-4ca2-bbf0-0b96dc42dfad.png",
                            title: "Pri Sig White"
                          }
                        }
                      },
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "8f839d39-ca06-4b08-9ed2-9c618ed6b36f.png",
                            title: "Bureau Veritas2x"
                          }
                        }
                      },
                      {
                        certificacion_imagen_id: {
                          imagen: {
                            filename_disk: "493d484a-28bf-4654-ba2b-8175a9f74643.png",
                            title: "Sello Mayor"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      public: true
    }

  }],
  failures: []
};

export const FOOTER_MOCKUP: Array<FooterColumn> = [
  {
    title: 'Información Útil',
    links: [
      {
        id: 2,
        title: 'Asociación AFP Chile',
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: 'Sitios de Interés',
    links: [
      {
        id: 1,
        title: 'Mapa del Sitio',
        url: 'url',
        externo: false
      },
      {
        id: 2,
        title: 'Asociación AFP Chile',
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: 'Mapa del Sitio',
        url: 'url',
        externo: false
      }
    ]
  }
];

export const GET_FOOTER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        links_columna_1: [
          {
            links_id: 2
          }
        ],
        links_columna_2: [
          {
            links_id: 1
          },
          {
            links_id: 2
          }
        ],
        links_columna_3: [
          {
            links_id: 1
          }
        ],
        titulos: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FOOTER_NULL_MOCKUP: Array<FooterColumn> = [
  {
    title: null,
    links: [
      {
        id: 2,
        title: null,
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: null,
        url: 'url',
        externo: false
      },
      {
        id: 2,
        title: null,
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: null,
        url: 'url',
        externo: false
      }
    ]
  }
];
