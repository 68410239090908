import { Mockup } from '@interfaces/mockup.interface';


export const PAYMENT_OPTIONS_RESPONSE_MOCKUP: Mockup = {
  success: [
    {
      response: {
        data: {
          id: 1,
          titulo: 'Optimiza tu tiempo y paga por Previred.',
          descripcion: '<p><strong>Puedes pagar hasta las 13:45 horas del d&iacute;a 13 de cada mes en los siguientes bancos: Santander, Chile, Estado, Scotiabank, BCI, Tbanc, Banco Ita&uacute;, Banco Security y Banco Bice.</strong></p>\n<p><strong>Banco Internacional, Banco Falabella y Banco HSBC s&oacute;lo permiten el pago hasta las 13:45 hrs. del d&iacute;a 10 o h&aacute;bil siguiente si este fuese s&aacute;bado, domingo o festivo.</strong></p>\n<p><strong>Tambi&eacute;n puedes&nbsp;<a href=\'https://www.bancoestado.cl/\'>pagar en BancoEstado</a>, servicio solo disponible en regiones.</strong></p>\n<p>Las Cotizaciones Previsionales deben ser pagadas dentro de los primeros 10 d&iacute;as del mes siguiente al que se devenga la remuneraci&oacute;n. &Eacute;ste plazo se prorroga hasta el d&iacute;a h&aacute;bil siguiente, si el d&iacute;a 10 es s&aacute;bado, domingo o festivo.</p>\n<p>Si no pagas las cotizaciones dentro del plazo establecido, la Ley se&ntilde;ala que dentro de ese mismo per&iacute;odo debes efectuar la \'Declaraci&oacute;n y No Pago de Cotizaciones\', las que estar&aacute;n sujetas al cobro de intereses y reajustes.</p>\n<p>Es importante tener presente que el art&iacute;culo 19 del D.L. 3500 establece una multa para aquellos Empleadores que no pagan ni declaran las Cotizaciones Previsionales dentro del plazo legal.</p>',
          texto_boton: 'Ir a PreviRed',
          url_boton: 'https://www.previred.com/',
          disclaimer: 'Si realizas el pago vía Internet, el plazo se extiende hasta las 23:00 horas del día 13.'
        }
      }
    }
  ],
  failures: []
};
