import { Mockup } from '@interfaces/mockup.interface';

export const AGREED_DEPOSITS_RESPONSE_MOCKUP: Mockup = {
    success: [
        {
            response: {
                "data": {
                    id: 1,
                    titulo: '¿Qué son los depósitos convenidos?',
                    descripcion: '<p><strong>Es un monto en dinero acordado entre empleador y trabajador dependiente, que se suma al ahorro obligatorio en la AFP.</strong></p>\n<h3>&iquest;Cu&aacute;les son las formas de pago?</h3>\n<ul>\n<li>Pagas el total de una sola vez o un porcentaje de la remuneraci&oacute;n imponible.</li>\n<li>Un monto fijo mensual.</li>\n</ul>\n<h3>&iquest;C&oacute;mo realizo el pago?</h3>\n<ul>\n<li>Completa el Formulario Planilla de Pago de Cotizaciones Voluntarias, Dep&oacute;sitos Convenidos y Aportes de Indemnizaci&oacute;n.</li>\n<li>El pago se realiza junto a las cotizaciones obligatorias.</li>\n</ul>\n<h3>&iquest;Cu&aacute;les son los beneficios del Dep&oacute;sito Convenido?</h3>\n<p><strong>Para el trabajador:</strong></p>\n<ul>\n<li>Aumentar tu pensi&oacute;n e inclusive la puedes adelantar.</li>\n</ul>\n<p><strong>Para el empleador:</strong></p>\n<ul>\n<li>Rebajar impuestos por incentivo al ahorro.</li>\n</ul>'
                }
            }
        }
    ],
    failures: []
};
