import { Component, OnInit } from '@angular/core';
import {
  FINANCIAL_STATEMENTS_BREADCRUMBS,
  FINANCIAL_STATEMENTS_CONTENT
} from '@constants/pages-content/financial-statements.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-financial-statements',
  templateUrl: './financial-statements.page.html',
  styleUrls: ['./financial-statements.page.scss'],
})
export class FinancialStatementsPage implements OnInit {
  public statementContent = FINANCIAL_STATEMENTS_CONTENT;
  public breadcrumb = [...FINANCIAL_STATEMENTS_BREADCRUMBS];
  public loading = false;

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit(): void {
    this.loadData();
    this.languageSubscription();
  }

  private loadData(): void {
    this.loading = true;
    this.cmsService.loadFinancialStatementsValues().toPromise()
      .then((response) => {
        this.statementContent.downloadContent = response.downloadContent;
        this.statementContent.header = response.header;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
