/**
 * Constante para definir las vistas que se deben mostrar con la etiqueta H1 en el banner de información.
 * El banner, dependiendo de la cantidad de elementos, puede desplegar una o más secciones, pero por reglas de SEO
 * solo debe existir un elemento H1. Los elementos indicados en esta constante mostrarán la etiqueta en el H1 del banner, en caso
 * contrario se utilizará la etiqueta P
 */
export const VIEW_WITH_H1_TAG: string[] = [
  '/afiliado/traspaso/por-que-cambiarme',
  '/afiliado/traspaso/estado',
  '/afiliado/ahorros/obligatorio',
  '/afiliado/ahorros/previsional-voluntario',
  '/afiliado/ahorros/cuenta-2',
  '/afiliado/asesoria/multifondos',
  '/afiliado/asesoria/simuladores',
  '/afiliado/asesoria/centro-tramites',
  '/afiliado/asesoria/consulta-solicitudes',
  '/pensionado/asesoria/centro-tramites',
  '/empleador/servicios/clave',
  '/empleador/servicios/depositos-convenidos',
  '/empleador/servicios/modalidades-pago',
  '/empleador/servicios/pago-exceso',
  '/empleador/pago-cotizaciones/planillas',
  '/empleador/pago-cotizaciones/comisiones',
  '/empleador/informacion/asignacion-familiar',
  '/empleador/informacion/renta-tope',
  '/empleador/informacion/tipos-trabajadores',
  '/empleador/informacion/subsidio-trabajador-joven',
  '/corporacion/quienes_somos/administracion_y_directorio',
  '/corporacion/informacion-relevante/estados-financieros',
  '/corporacion/politicas-y-estatutos/gobierno-corporativo',
  '/corporacion/informacion-relevante/hechos-esenciales',
  '/corporacion/informacion-relevante/informacion-de-interes',
  '/corporacion/informacion-relevante/memoria-anual',
  '/corporacion/politicas-y-estatutos/politicas-afp',
  '/corporacion/informacion-relevante/mapa-del-sitio',
  '/corporacion/quienes-somos/nuestra-compania',
  '/preguntas_frecuentes',
  '/afiliado/asesoria/informacion-financiera/valor-cuota',
  '/afiliado/asesoria/informacion-financiera/indicadores-economicos',
  '/afiliado/asesoria/informacion-financiera/cartera-inversiones',
  '/afiliado/asesoria/informacion-financiera/variacion-rentabilidad'
];
