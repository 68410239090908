import { Mockup } from '@interfaces/mockup.interface';

export const PAPERWORK_CENTER_PENSIONER_RESPONSE: Mockup = {
    success: [{
        response: {
            "data": [
                {
                    "id": 1,
                    "sort": null,
                    "titulo": "Inscribir mi cuenta bancaria",
                    "descripcion": "<p>Si todav&iacute;a cobras tu pensi&oacute;n de forma presencial, te invitamos a inscribir tu cuenta bancaria antes del 5 de cada mes para recibir tu dinero por transferencia electr&oacute;nica.</p>"
                },
                {
                    "id": 2,
                    "sort": null,
                    "titulo": "Pensión por Sobrevivencia",
                    "descripcion": "<p>Es un beneficio que otorga la AFP a los beneficiarios o herederos directos tras el fallecimiento del afiliado (a).</p>"
                },
                {
                    "id": 3,
                    "sort": null,
                    "titulo": "Solicitud Herencia: Posesión Efectiva",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 4,
                    "sort": null,
                    "titulo": "Solicitud Cuota Mortuoria: Asignación por Muerte",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 5,
                    "sort": null,
                    "titulo": "Excedente de Libre Disposición",
                    "descripcion": "<p>Completa el formulario desde tu Sucursal en l&iacute;nea, a trav&eacute;s del siguiente enlace</p>"
                },
                {
                    "id": 6,
                    "sort": null,
                    "titulo": "Aporte Previsional Solidario (APS) o Pensión Garantizada Universal (PGU)",
                    "descripcion": "<p>Descarga, completa y env&iacute;a este formulario al correo&nbsp;<a href=\"mailto:pensionados@planvital.cl\">pensionados@planvital.cl</a>&nbsp;con los siguientes documentos:</p>\n<ul>\n<li>Foto de tu C&eacute;dula de Identidad</li>\n</ul>"
                },
                {
                    "id": 7,
                    "sort": null,
                    "titulo": "Solicitud Garantía Estatal Pensiones",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 8,
                    "sort": null,
                    "titulo": "Reevaluación de invalidez",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea desde tu cuenta en el sitio privado, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 9,
                    "sort": null,
                    "titulo": "Cobro de Honorarios de Asesor Previsional",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 10,
                    "sort": null,
                    "titulo": "Acreditar Estudios para Pensión de Sobreviviencia",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 11,
                    "sort": null,
                    "titulo": "Selección de Modalidad y Aceptación de Oferta",
                    "descripcion": "<p>Descarga, completa y env&iacute;a este formulario al correo&nbsp;<a href=\"mailto:pensionados@planvital.cl\">pensionados@planvital.cl</a>&nbsp;con los siguientes documentos:</p>\n<ul>\n<li>Foto de tu C&eacute;dula de Identidad</li>\n</ul>"
                },
                {
                    "id": 12,
                    "sort": null,
                    "titulo": "Solicitud de Oferta",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea desde tu cuenta en el sitio privado, a trav&eacute;s del siguiente enlace</p>"
                },
                {
                    "id": 13,
                    "sort": null,
                    "titulo": "Asignación Familiar",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>"
                },
                {
                    "id": 14,
                    "sort": null,
                    "titulo": "Transferencia de saldos de APV y Depósitos Convenidos para pago de pensión",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea desde tu cuenta en el sitio privado, a trav&eacute;s del siguiente enlace</p>"
                },
                {
                    "id": 15,
                    "sort": null,
                    "titulo": "Transferencia de saldos de Cuenta 2 para pago de pensión",
                    "descripcion": "<p>Completa este formulario en l&iacute;nea desde tu cuenta en el sitio privado, a trav&eacute;s del siguiente enlace</p>"
                }
            ]
        }
    }],
    failures: []
};
