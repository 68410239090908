import { environment } from '@environment';
import { EconomicIndicatorsInfo } from '@interfaces/financial-information.interface';
import { Mockup } from '@interfaces/mockup.interface';


export const MATERIALIZATION_DATE_MOCK: Mockup = {
  success: [{
    response: {
      date: '09/08/2018',
    },
  }],
  failures: [{
    response: {
      success: false
    },
  }],
};


export const FINANCIAL_INDICATORS_MOCKUP: Mockup = {
  success: [{
    response: {
      ipcDate: '2020-01-01',
      ipcValue: 0.6,
      utmDate: '2020-03-01',
      ufValue: 28481.29,
      utmValue: 50021,
      afpPercentage: 0.1,
      healthPercentage: 0.07
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_FINANCIAL_INDICATORS_CONTENT_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        banner: {
          image: {
            url: environment.cmsApiUrl + 'test.webp',
            alt: 'Indicadores Económicos: UF - UTM - IPC AFP PlanVital'
          },
          image_mobile: {
            url: environment.cmsApiUrl + 'test.webp',
            alt: 'Indicadores Económicos: UF - UTM - IPC AFP PlanVital'
          },
          firstLine: 'Indicadores Económicos: UF - UTM - IPC'
        },
        reporte: {
          filename_disk: '001.pdf',
          filename_download: 'test.pdf'
        },
        traducciones: [
          {
            language_code: 'es',
            titulo: 'Indicadores Económicos: UF - UTM - IPC',
            texto_boton: 'Descargar Reporte',
          },
          {
            language_code: 'ht',
            titulo: 'Endikatè Ekonomik yo: UF - UTM - IPC',
            texto_boton: 'Telechaje rapò a',
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FINANCIAL_INDICATORS_CONTENT_MOCKUP: EconomicIndicatorsInfo = {
  reportFileUrl: `${environment.cmsApiUrl}/assets/001.pdf`,
  title: 'Indicadores Económicos: UF - UTM - IPC',
  textButton: 'Decargar Reporte',
  fileName: 'test.pdf',
  header: {
    image: {
      url: environment.cmsApiUrl + '311f8ce2-f7ff-40a4-b85d-b2d8738ab5ee.webp',
      alt: 'Indicadores Económicos: UF - UTM - IPC AFP PlanVital'
    },
    image_mobile: {
      url: environment.cmsApiUrl + '311f8ce2-f7ff-40a4-b85d-b2d8738ab5ee.webp',
      alt: 'Indicadores Económicos: UF - UTM - IPC AFP PlanVital'
    },
    firstLine: 'Indicadores Económicos: UF - UTM - IPC'
  }
};

export const LAST_TAX_YEARS_MOCKUP: Mockup = {
  success: [{
    response: {
      years: [
        2019,
        2018
      ]
    }
  }],
  failures: [{
    response: {}
  }]
};

export const QUOTA_VALUES_FILE_MOCKUP: Mockup = {
  success: [{
    response: {
      base64:
      'UEsDBAoAAAAIAPidg1OR28AJWQEAAPAEAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbK2UTW7CMBCF9z1F5C1KDF1UVUXCorTLFqn0' +
      'ANN4Qiwc2/KYv9t3EiiqKiCqYBMrmTfve57EGU+2jUnWGEg7m4tRNhQJ2tIpbRe5+Jy/po8ioQhWgXEWc7FDEpPibjzfeaSEmy3l' +
      'oo7RP0lJZY0NUOY8Wq5ULjQQ+TYspIdyCQuU98PhgyydjWhjGlsPUYynWMHKxORly4/3QQIaEsnzXtiycgHeG11C5LpcW/WHkh4I' +
      'GXd2Gqq1pwELhDxJaCvnAYe+d55M0AqTGYT4Bg2r5NbIjQvLL+eW2WWTEyldVekSlStXDbdk5AOCohoxNibr1qwBbQf9/E5MsltG' +
      'Nw5y9O/JEfl94/56fYTOpgdIcWeQbj32zrSPXENA9REDH4ybB/jtfeGTXV9J5f5pgA1Tzm2UpbPgPPERDfj/Xf6cwbY79WyEIerL' +
      'oz0S2frqsWI7K4XqBFt2P6ziG1BLAwQKAAAAAAD4nYNTAAAAAAAAAAAAAAAABgAAAF9yZWxzL1BLAwQKAAAACAD4nYNT8p9J2ukA' +
      'AABLAgAACwAAAF9yZWxzLy5yZWxzrZLBTsMwDEDvfEXk+5puSAihpbsgpN0mND7AJG4btY2jxIPu74mQQAyNaQeOceznZ8vrzTyN' +
      '6o1S9hwMLKsaFAXLzofOwMv+aXEPKgsGhyMHMnCkDJvmZv1MI0qpyb2PWRVIyAZ6kfigdbY9TZgrjhTKT8tpQinP1OmIdsCO9Kqu' +
      '73T6yYDmhKm2zkDauiWo/THSNWxuW2/pke1hoiBnWvzKKGRMHYmBedTvnIZX5qEqUNDnXVbXu/w9p55I0KGgtpxoEVOpTuLLWr91' +
      'HNtdCefPjEtCt/+5HJqFgiN3WQlj/DLSJzfQfABQSwMECgAAAAAA+J2DUwAAAAAAAAAAAAAAAAMAAAB4bC9QSwMECgAAAAAA+J2D' +
      'UwAAAAAAAAAAAAAAAAkAAAB4bC9fcmVscy9QSwMECgAAAAgA+J2DU4QksVbpAAAAuQIAABoAAAB4bC9fcmVscy93b3JrYm9vay54' +
      'bWwucmVsc62SwWrDMBBE7/0KsfdadlpKKZFzKYFcW/cDhLS2TGxJaDdt/fdVG0gcCKEHn8Ss2JnHSOvN9ziIT0zUB6+gKkoQ6E2w' +
      've8UfDTb+2cQxNpbPQSPCiYk2NR36zccNOcdcn0kkU08KXDM8UVKMg5HTUWI6PNNG9KoOcvUyajNXncoV2X5JNPcA+oLT7GzCtLO' +
      'ViCaKeJ/vEPb9gZfgzmM6PlKhCSehswvGp06ZAVHXWQfkNfjV0vGc97Fc/qfPA6rWwwPi1bgdEL7zik/8LyJ+fgWzOOSMF8h7ckh' +
      '8hnkNPpFzcepGXnx4+ofUEsDBAoAAAAAAPidg1MAAAAAAAAAAAAAAAAOAAAAeGwvd29ya3NoZWV0cy9QSwMECgAAAAgA+J2DUyuk' +
      'VoHLAgAAAAgAABgAAAB4bC93b3Jrc2hlZXRzL3NoZWV0MS54bWyVVdFumzAUfd9XIL83EErSgICqCYk2aZOmrdOeXWMSqwYj20na' +
      'ff0upqTYVGr7EsXnHp97j699SW+fau6dqFRMNBmazwLk0YaIkjX7DP25312tkKc0bkrMRUMz9EwVus2/pGchH9WBUu2BQKMydNC6' +
      'TXxfkQOtsZqJljYQqYSssYal3PuqlRSXZlPN/TAIln6NWYN6hUR+RENUFSO0EORY00b3IpJyrKF8dWCtGtRq8hG5GsvHY3tFRN2C' +
      'xAPjTD8bUeTVJPm2b4TEDxxsP80jTAZts5jI14xIoUSlZyD3UujUc+zHPijlacnAQXfqnqRVhu7CZDcPkJ+nhrwzRf6UXkkrfOT6' +
      'lzh/pWx/0NCjBfLEUXPW0O/0RDmEMhTY2EZwg5lKk/K5oIrAeWVosehSEMGV+fVq1nUd3OKnDIHrMyv1IUMh7CVHpUX9twfm3Ta/' +
      '32cKLLDGeSrF2YO+hXBHWtzdgkWyfCNrMAsXYJl03G1HNjkhoAA95UHqnzr1F8ZuYHQpIcMlTXRJEyaLd9KsIyMSjtLM7TSbgeG/' +
      'AIULbEeAVcjyUsj8Xb93SyNyPSoktAtZD4wh78YFChfYusBuBFilrj5T6sqIRKNSr51Sp4zIOdUpY2EziiljaTO2U8aNc0WmjNWF' +
      'YfmPP+M/HunFjvNxbO7c2I0VdO5ZYQWd3m+toHPaOysYvW0QhsYnHHbsV0mnM2s76nRlY0edjhR2dOXYtKPO2e6saBg4Rv3RwKmp' +
      '3NMN5Vx5RBw7e9dohPazdAuz1DxaB19HCTzpKX63TOD9dC/nVT5PW7ynP7Dcs0Z5nFbmJG+QJ/spbP5r0Zp/MIwehIZpOawOMPSp' +
      '7Fbw7ish9LDwe93fVB9bT0gGHTLfrgy1QmqJmYbNgP8TEOBFy+CSh3EUL2/CGHThQ60ZeSOgAKTdZYCTrJi+F5e53S8vHw9j8/Lx' +
      'zv8DUEsDBAoAAAAIAPidg1PN7N7gjQEAADMDAAAUAAAAeGwvc2hhcmVkU3RyaW5ncy54bWxtkl+OmzAQxt97ipGfF2OcYKACVllC' +
      '+lI1q/337oVpQAI7xU7VHqIX6B16ilxsba20qoAHy+Ofv5n5PHJ++2sc4CdOpteqIBFlBFA1uu3VqSDPT4cgJWCsVK0ctMKC/EZD' +
      'bstPuTEWXKoyBemsPX8OQ9N0OEpD9RmVu/mup1Fad5xOoTlPKFvTIdpxCDljIhxlrwg0+qJsQXhE4KL6HxesPkCZm77Mbfkole3l' +
      'Sd/ABlqEtm96HF8n9AfOeJSHtsxDr33Xv+y+Hh+gej4+7eCx/nL9+w3u64frn+P+OJfWxmoDYLQCGHz04h45oQuqi7YSfIv3i4NW' +
      'rd89uEflh+Vlu+AuqIJ9UAOc5eQScHARTtd/utUA7j2uulud9PuATeMSZavp3MkBm06aBfVdYbeO79ZxtY7367ieYz/RIOIBW8w1' +
      'TjlPqNgsONvGnKbpnGciY4JmfM43IkoimiVznjDBEpos6n84WlSKE5HFNN4uHUURp9mKIyE2Kx2co3RLt4s6CUvimEb/6UP368s3' +
      'UEsDBAoAAAAAAPidg1MAAAAAAAAAAAAAAAAJAAAAeGwvdGhlbWUvUEsDBAoAAAAIAPidg1N2mzDfIQYAABkfAAATAAAAeGwvdGhl' +
      'bWUvdGhlbWUxLnhtbO1ZTW/bNhi+71cQurfyl1InqFPEjt1ubdogcTv0SEu0xIYSBZJO4tvQHgcMGNYNuwzYbYdhW4EW2KX7Ndk6' +
      'bB3Qv7BX1ocpm2qcJt1QIDk4IvU87xff9yVpX79xHDJ0SISkPOpY9as1C5HI5R6N/I51fzi40raQVDjyMOMR6VhTIq0bmx9dxxsq' +
      'ICFBQI/kBu5YgVLxhm1LF6axvMpjEsG7MRchVjAUvu0JfARiQ2Y3arU1O8Q0slCEQ5B6bzymLkHDRKS1iXLpfQYfkZKzGZeJfXem' +
      'U+ekaO+gPvsvp7LHBDrErGOBLo8fDcmxshDDUsGLjlWb/Vk2oO05jakqukYdzP5yak7xDhopVfijglsftNavbc+1NDItBmi/3+/1' +
      '63OpKQS7LvhdX4a3Bu16t5Csw9Jng4Zezam1Fii6luYyZb3b7TrrZUpTo7SWKe3aWmurUaa0NIpj8KW71eutlSmORllbpgyura+1' +
      'FigpLGA0OlgmJKs9X7Q5aMzZLTOjDYx2kSEaztZSMJMRqcqMDPEjLgaASJceKxohNY3JGLuA7OFwJCieacEbBGuvsjlXLs8lCpF0' +
      'BY1Vx/okxlA+c8yblz+9efkcvXn57OTxi5PHv548eXLy+BcT8xaOfJ35+ocv//nuM/T38+9fP/26giB1wh8/f/77b19VIJWOfPXN' +
      'sz9fPHv17Rd//fjUhN8SeKTjhzQkEt0lR2iPh+CfSQUZiTNShgGmJQoOAGpC9lVQQt6dYmYEdkk5hg8EtAsj8ubkUcne/UBMFDUh' +
      'bwdhCbnDOetyYfbpdqJO92kS+RX6xUQH7mF8aFTfW1jl/iSG3KZGob2AlEzdZbDw2CcRUSh5xw8IMfEeUlqK7w51BZd8rNBDirqY' +
      'mgMzpCNlZt2iISzQ1GgjrHopQjsPUJczo4JtcliGQoVgZhRKWCmaN/FE4dBsNQ6ZDr2DVWA0dH8q3FLgpYJF9wnjqO8RKY2ke2Ja' +
      'Mvk2hjZlzoAdNg3LUKHogRF6B3OuQ7f5QS/AYWy2m0aBDv5YHkDGYrTLldkOXq6ZZAwLgqPqlX9AiTpjsd+nfmBOluTNRBhrhPBy' +
      'jU7ZGJMo3wTKvTyk0Vs7O6PQ2i87+0Jn34LtzlhRi/28EviBdvFtPIl2CVTKZRO/bOKXTfxtFf4+WrfWrG39yJ5KCqsP8GPK2L6a' +
      'MnJHpp1egpveAGbT0YxX3BriAB5zpWWkL/BsgARXn1IV7Ac4Bl31VI0vc/m+RDGXcGWxqhWkV2MK/s8mneIyC3isdriXzjdLt9xC' +
      'Ujr0ZUldMxGyusrmtfOrrKfYlXXWnQqdzmk6bT3AUFsIJ19r1NcaqQWQRZgRL1mMTEi+WO975eo1fekC7BHTvOZrvfn+4uuc0ZaL' +
      'i3vNEHfbUHssWhiio4617jQcC7k47lhjOIbBYxiDTJk0KMz8qGO5KvN1hdpd9H69IunqNafa+bKeWEi1jWWQEmfvii96Is2RhtNK' +
      'gnJRnhi70Kq2NNv1/90We2nByXhMXFU1pY3zt3yiiNgPvCM0YhOxh8GDVpp6HpWwbTTygYD0b2VZWS7zvIAWv07KKwuzOMBZQbT1' +
      'lEgJ6aCwIx3qRtpVPryzT80L9cm59Cnf+V04Eze92bMLBwWBUZLCHYsLFXBoXXFA3YGAs0WqEexDUDqJaYglX6snNpNDrd2lUrLu' +
      '6Adqj/pIUGiRKhCE7KrM49Pk1RulXTcXlbemudUyzh5G5JCwYVLoa0kwLBTk7SePSopcWkjbWIQjf/ABHJNa77yPzdW1zraltvTd' +
      'Q9tU1s9vyWq7u6a0UeF+w3nLTra8jcdw9UHJB+wAVLhMOycP+R5kBiqOEghy9Uo7K9ZicgS2t3U/E2H/7bGrXZUJF3561eLfrIr/' +
      'qUrPE3/HEH7n1Ojbhpq2tYtSOlz+cY6PHoEF23AJm7BsSsYwzJ52Rer+iHvT/JnJtJdkgSk2CBbtkTGi3nGx5AtRzn71mh8Z9jI9' +
      'SSgKbnMVbsbQNqaC31iFX3A284tpwZ/dPI0ymKY/ZWQZMG+189ix6NxRXMmTiiia83z1KK60gu8URXV8ahTz2NnG/CTHSuBe/ose' +
      'pLqtJffmv1BLAwQKAAAACAD4nYNTP5fmtP8CAABACQAADQAAAHhsL3N0eWxlcy54bWzFVltvmzAUft+vsPxOuSRkSRRSNU2RKnXT' +
      'pGbSXg0YYtUXZExHNu2/zzYQiLouvWiaIsU+n8/5znfsA2Z12TAKHrGsiOAR9C88CDBPRUZ4EcGvu9iZQ1ApxDNEBccRPOAKXq4/' +
      'rCp1oPh+j7ECmoFXEdwrVS5dt0r3mKHqQpSY65VcSIaUNmXhVqXEKKtMEKNu4HkzlyHCYcuwZOlLSBiSD3XppIKVSJGEUKIOlgsC' +
      'li5vCy4kSqhW2vhTlILGn8mgz2ChJ0kYSaWoRK4uNKkr8pyk+KnWhbtwUTowadq3Mfmh6wVt4etVLriqQCpqriIYanajcPnAxXce' +
      'myV9JJ3XepUKKiRQOhU2sKtxxAg9gEdEIxgYwArBLcCI3goL/mgB38Zw1DtcI0oSSQzothna/95/fup+JQmiT5yTcQLvJRGv8x7z' +
      'nxFkh0oHEkqPezqBLbBe6XZRWPJYG6Cb7w6l3kquG7ulsX5nvAuJDn4QvjygEpRkRkVxbQ9QFkkENwvzMyRJB6Naie5Y3RHXMYsd' +
      'dHWJkJl+XPv6THu00HpFca50uCTF3oxKlIZfKKWbcb3KCCoER9Qk6CO6iaZNMaX35pn+lp9wNzngNYuZus0iqF8OZo/7qRbUTVua' +
      '1jD8Y7aWe0QbvokWNPmR/7lo/3w0QGVJD+bZsu7WuqKk4Az3JaPeBHshyQ/tarrN7qotrsmfVxD8QwWpBrA8K2Hy/yVMBwnBKySY' +
      'fn+fILdrtVE/n3TzEQXmNRLBz+ZioSNVSU2oIvwPnaw5s2ZoYruqzE1zmkVzZDhHNVW742IEh/knnJGaBUevL+RRqM5rmN+ZZvNn' +
      'Jgdu1F2l7AhqSSL482bzcbG9iQNn7m3mznSCQ2cRbrZOOL3ebLfxwgu861+jK+8dF153S2mSZUW1l+yK7cTfD1gER0Yr3+6flj3W' +
      'vghm3lXoe0488XxnOkNzZz6bhE4c+sF2Nt3chHE40h6+8Yr1XN8fxIdLRRimhONT+bsxqg9Jm38pwu1Pwh2+fda/AVBLAwQKAAAA' +
      'AAD4nYNTAAAAAAAAAAAAAAAACQAAAGRvY1Byb3BzL1BLAwQKAAAACAD4nYNT+AjlNIkBAAAuAwAAEAAAAGRvY1Byb3BzL2FwcC54' +
      'bWydUsFu2zAMve8rDN0bOd1QDIGsokg39LBiAZKmZ1amY6GyZIiMkezrJzuI47Q77fZIPjw9kU/dHxqXdRjJBl+I+SwXGXoTSut3' +
      'hXjZ/Lz5LjJi8CW44LEQRyRxr7+oVQwtRrZIWVLwVIiauV1ISabGBmiWxj5NqhAb4FTGnQxVZQ0+BrNv0LO8zfM7iQdGX2J5046C' +
      '4qS46Ph/Rctgen+03RzbpKfVQ9s6a4DTJ/WzNTFQqDj7cTDolJwOVRJao9lHy0edKzkt1dqAw2US1hU4QiUvDfWE0O9sBTaSVh0v' +
      'OjQcYkb2T9rarcjegLC3U4gOogXP4kQ7FQN2LXHUryG+U43IpOTYHOCUO8X2m54PhASuiXI0kvC1xY1lh/S7WkHkfzieTx0PHsTE' +
      '4wqjDWXYplDE5T4wfLJ6fvTDM8vQtODTLuWInsHDDnvuiH5Z/04v7SY8AuN52ddNta4hYpnuMx5jbKin5Dq6nr+swe+wPHM+D/po' +
      'bE/x1/O7Wf41z4dEnHtKXpKu/wJQSwMECgAAAAgA+J2DU3MoM4NfAQAA4wIAABEAAABkb2NQcm9wcy9jb3JlLnhtbJ1Sy27CMBC8' +
      '9ysi34MTQC2NQpDailORKpWqVW+uvYBLYlv20pC/r/MgEJVTbzs7s+N9OF0cizz4AeukVnMSjyISgOJaSLWdk7f1MpyRwCFTguVa' +
      'wZxU4Mgiu0m5Sbi28GK1AYsSXOCNlEu4mZMdokkodXwHBXMjr1Ce3GhbMPTQbqlhfM+2QMdRdEsLQCYYMlobhqZ3JJ2l4L2lOdi8' +
      'MRCcQg4FKHQ0HsX0rEWwhbta0DAXykJiZeCq9ET26qOTvbAsy1E5aaS+/5h+rJ5fm1FDqepVcSBZKnjCLTDUNntTe6VLldKLXM2j' +
      'xByyJt2FPnKHr2/g2KZ74GMBjltp0N+pJQcJf449VKW2wnl2gOpLMYSttlVLnZEHOXO48ufeSBAP1bnXv1Ta7badAUTgd5K0Gzwx' +
      '75PHp/WSZONoHIfxOIwm6/g+md4l09ln3fOg/mxYdI/82/Fk0M03+JfZL1BLAwQKAAAACAD4nYNTYxEAZWEBAAB8AgAADwAAAHhs' +
      'L3dvcmtib29rLnhtbI2STW/CMAyG7/sVUe6j7djGhihI+5K4TBzY7iFxISKJIydl8O/nFjpp2mWXOInjx6/tzBZH78QBKFkMtaxG' +
      'pRQQNBobtrX8WL9dP0iRsgpGOQxQyxMkuZhfzb6Q9hvEveD4kGq5yzlOiyLpHXiVRhghsKdB8irzkbZFigTKpB1A9q64Kcv7wisb' +
      '5Jkwpf8wsGmshhfUrYeQzxACpzKrTzsb00Dz+j84r2jfxmuNPjJiY53Npx4qhdfT5TYgqY3jqo/V3UDm7R+0t5owYZNHjLqI/FNv' +
      'VRZVdS55Pmusg89z14WK8V35LouTwqmUX43NYGrJOR1+wa8LauNTax0fHsflWBbzn0msSBhoVOvymlUNdJ7p/W1ZVVJwygy0IntQ' +
      '+sTXXWyvLl2s6Nel6Xwi9IpWQBYNfvLw6bnFrPrPkNlzsMlya1jQ1HIELc1tBywGolZOs6LO9MRJVd5M+heD3vk3UEsBAhQACgAA' +
      'AAgA+J2DU5HbwAlZAQAA8AQAABMAAAAAAAAAAAAAAAAAAAAAAFtDb250ZW50X1R5cGVzXS54bWxQSwECFAAKAAAAAAD4nYNTAAAA' +
      'AAAAAAAAAAAABgAAAAAAAAAAABAAAACKAQAAX3JlbHMvUEsBAhQACgAAAAgA+J2DU/KfSdrpAAAASwIAAAsAAAAAAAAAAAAAAAAA' +
      'rgEAAF9yZWxzLy5yZWxzUEsBAhQACgAAAAAA+J2DUwAAAAAAAAAAAAAAAAMAAAAAAAAAAAAQAAAAwAIAAHhsL1BLAQIUAAoAAAAA' +
      'APidg1MAAAAAAAAAAAAAAAAJAAAAAAAAAAAAEAAAAOECAAB4bC9fcmVscy9QSwECFAAKAAAACAD4nYNThCSxVukAAAC5AgAAGgAA' +
      'AAAAAAAAAAAAAAAIAwAAeGwvX3JlbHMvd29ya2Jvb2sueG1sLnJlbHNQSwECFAAKAAAAAAD4nYNTAAAAAAAAAAAAAAAADgAAAAAA' +
      'AAAAABAAAAApBAAAeGwvd29ya3NoZWV0cy9QSwECFAAKAAAACAD4nYNTK6RWgcsCAAAACAAAGAAAAAAAAAAAAAAAAABVBAAAeGwv' +
      'd29ya3NoZWV0cy9zaGVldDEueG1sUEsBAhQACgAAAAgA+J2DU83s3uCNAQAAMwMAABQAAAAAAAAAAAAAAAAAVgcAAHhsL3NoYXJl' +
      'ZFN0cmluZ3MueG1sUEsBAhQACgAAAAAA+J2DUwAAAAAAAAAAAAAAAAkAAAAAAAAAAAAQAAAAFQkAAHhsL3RoZW1lL1BLAQIUAAoA' +
      'AAAIAPidg1N2mzDfIQYAABkfAAATAAAAAAAAAAAAAAAAADwJAAB4bC90aGVtZS90aGVtZTEueG1sUEsBAhQACgAAAAgA+J2DUz+X' +
      '5rT/AgAAQAkAAA0AAAAAAAAAAAAAAAAAjg8AAHhsL3N0eWxlcy54bWxQSwECFAAKAAAAAAD4nYNTAAAAAAAAAAAAAAAACQAAAAAA' +
      'AAAAABAAAAC4EgAAZG9jUHJvcHMvUEsBAhQACgAAAAgA+J2DU/gI5TSJAQAALgMAABAAAAAAAAAAAAAAAAAA3xIAAGRvY1Byb3Bz' +
      'L2FwcC54bWxQSwECFAAKAAAACAD4nYNTcygzg18BAADjAgAAEQAAAAAAAAAAAAAAAACWFAAAZG9jUHJvcHMvY29yZS54bWxQSwEC' +
      'FAAKAAAACAD4nYNTYxEAZWEBAAB8AgAADwAAAAAAAAAAAAAAAAAkFgAAeGwvd29ya2Jvb2sueG1sUEsFBgAAAAAQABAAxgMAALIX' +
      'AAAAAA=='
    }
  }],
  failures: [{
    response: {}
  }]
};

export const QUOTA_VALUES_MOCKUP: Mockup = {
  success: [{
    response: {
      quotaValues: [
        {
          date: '2020-03-01',
          fundA: 47585.3,
          fundB: 42502.42,
          fundC: 88370.82,
          fundD: 35293.81,
          fundE: 71492.44
        },
        {
          date: '2020-03-02',
          fundA: 46945.28,
          fundB: 42005.39,
          fundC: 87578.19,
          fundD: 35099.52,
          fundE: 71336.59
        },
        {
          date: '2020-03-03',
          fundA: 47626.94,
          fundB: 42443.14,
          fundC: 88099.3,
          fundD: 35227.03,
          fundE: 71521.1
        }
      ]
    }
  }],
  failures: [{
    response: {}
  }]
};

export const UF_VALUE_MOCKUP: Mockup = {
  success: [{
    response: {
      ufDate: '2020-03-30',
      ufValue: 28593.78
    }
  }],
  failures: [{
    response: {}
  }]
};

export const UTM_VALUE_MOCKUP: Mockup = {
  success: [{
    response: {
      utmDate: '2020-03-30',
      utmValue: 50021
    }
  }],
  failures: [{
    response: {}
  }]
};

