import { Mockup } from '@interfaces/mockup.interface';

export const SITEMAP_RESPONSE_MOCKUP: Mockup = {
    success: [{
        response: {
            data: {
                secciones: [{
                    mapa_del_sitio_seccion_id: {
                        modulo: "Afiliados",
                        detalles: [
                            {
                                mapa_del_sitio_detalle_id: {
                                    seccion: "Home",
                                    seccion_url: [
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Inicio Afiliados",
                                                url: "afiliado/inicio"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                mapa_del_sitio_detalle_id: {
                                    seccion: "Cámbiate a AFP PlanVital",
                                    seccion_url: [
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Me quiero cambiar",
                                                url: "https://traspaso.planvital.cl/"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "¿Por qué cambiarme?",
                                                url: "afiliado/traspaso/por-que-cambiarme"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Estado de tu traspaso",
                                                url: "afiliado/traspaso/estado"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                mapa_del_sitio_detalle_id: {
                                    seccion: "Ahorra con AFP PlanVital",
                                    seccion_url: [
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Ahorro obligatorio",
                                                url: "afiliado/ahorros/obligatorio"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Ahorro Previsional Voluntario (APV)",
                                                url: "afiliado/ahorros/previsional-voluntario"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Cuenta de ahorro",
                                                url: "afiliado/ahorros/cuenta-2"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                mapa_del_sitio_detalle_id: {
                                    seccion: "Asesoría y herramientas",
                                    seccion_url: [
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Información financiera",
                                                url: "afiliado/asesoria/informacion-financiera"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Multifondos",
                                                url: "afiliado/asesoria/multifondos"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Futuro pensionado",
                                                url: "afiliado/asesoria/futuro-pensionado"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Simuladores",
                                                url: "afiliado/asesoria/simuladores"
                                            }
                                        },
                                        {
                                            mapa_del_sitio_url_id: {
                                                titulo: "Certificados en línea",
                                                url: "afiliado/asesoria/certificados"
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }]
            }
        }
    }
    ],
    failures: []
};
