import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_FINANCIAL_STATEMENTS_LINKS: Mockup = {
   success: [{
      response: {
         data: {
            texto_administradora: 'Estados Financieros Trimestrales de la Administradora',
            texto_fondo: 'Estados Financieros Trimestrales de los Fondos de Pensiones Tipos A, B, C, D y E',
            estados: [
               {
                  estados_financieros_seccion_sort_id: {
                     titulo: '30 de septiembre 2024',
                     subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 30 de septiembre 2024 y sus notas',
                     administradora: [
                        {
                           estados_finacieros_archivo_id: {
                              titulo: '30 de septiembre 2024-1',
                              archivo: {
                                 filename_disk: '118554c8-b276-4de7-8340-5dd85dd33991.pdf'
                              }
                           }
                        }
                     ],
                     fondo: [
                        {
                           estados_finacieros_archivo_id: {
                              titulo: '30 de septiembre 2024',
                              archivo: {
                                 filename_disk: 'fc90b798-17dd-489c-8b51-a405101cd0fb.pdf'
                              }
                           }
                        }
                     ]
                  }
               },
               {
                  estados_financieros_seccion_sort_id: {
                     titulo: '30 de junio 2023',
                     subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 30 de junio 2023 y sus notas',
                     administradora: [
                        {
                           estados_fiancieros_archivo_id: {
                              titulo: '30 de junio',
                              archivo: {
                                 filename_disk: 'b721bd7a-2c4b-4cb9-a55b-f2bf4bc43e13.pdf'
                              }
                           }
                        },
                        {
                           estados_fiancieros_archivo_id: {
                              titulo: 'Ficha Estadística',
                              archivo: {
                                 filename_disk: '28761c7a-4ea6-44a6-8aeb-c99db0cd560d.pdf'
                              }
                           }
                        },
                        {
                           estados_finacieros_archivo_id: {
                              titulo: 'Notas a los Estados Financieros',
                              archivo: {
                                 filename_disk: '6266c8c2-59b3-422d-b211-35d8c845580c.pdf'
                              }
                           }
                        },
                        {
                           estados_finacieros_archivo_id: {
                              titulo: 'Hechos relevantes',
                              archivo: {
                                 filename_disk: 'f39037f6-2cec-4b30-9dde-f8bba6c1a775.pdf'
                              }
                           }
                        },
                        {
                           estados_finacieros_archivo_id: {
                              titulo: 'Análisis Razonado',
                              archivo: {
                                 filename_disk: '72ab9011-29a2-46be-934d-3efc15cb39b2.pdf'
                              }
                           }
                        },
                        {
                           estados_finacieros_archivo_id: {
                              titulo: 'Declaración de Responsabilidad',
                              archivo: {
                                 filename_disk: '1a7c35d8-af40-411e-b666-4f9eb924a5cd.pdf'
                              }
                           }
                        }
                     ],
                     fondo: [
                        {
                           estados_finacieros_archivo_id: {
                              titulo: '30 de junio 2023',
                              archivo: {
                                 filename_disk: 'd21d1289-6c37-4f5e-b8b4-03807da2f7ff.pdf'
                              }
                           }
                        }
                     ]
                  }
               }
            ]
         }
      }
   }],
   failures: [{
      response: {}
   }]
};
